import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "../../components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "../../configs/AppConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const AppViews = () => {
  const history = useHistory();
  useEffect(() => {
    const urlObj = new URL(window.location.href);
    const params = Object.fromEntries(urlObj.searchParams.entries());

    if (Object.keys(params).length > 0) {
      localStorage.setItem("paramsListData", JSON.stringify(params));
      if (params.task === "approve" && localStorage.getItem("token")) {
        history.push("/auth/approve-instance");
      }
    }
  }, [history]);

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/otp`}
          component={lazy(() => import(`./authentication/otp`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import(`./authentication/forgot-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/reset-password`}
          component={lazy(() => import(`./authentication/reset-password`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/approve-instance`}
          component={lazy(() => import(`./authentication/Approve`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import(`./errors/error-page-1`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/callback`}
          component={lazy(() => import(`./callbackPage`))}
        />
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
